<template>
    <div class="nonuserspace_container">
        <h2 class="title_lg nonuserspace_title font_white txt_center">
            지금 이순간, <br />
            내 공간에 어울리는 음악을 <br />
            만나보세요.
        </h2>
        <h3 class="nonuserspace_subtitle">30초면 끝! 지금, 어플레이즈를 시작해보세요.</h3>
        <div class="nonuserspace_btn_wrap">
            <button class="nonuserspace_btn" @click="makeSpace">시작하기</button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'NonUserSpace',
  props: {},
  data () {
    return {};
  },

  mounted () {},

  methods: {
    makeSpace () {
      this.$store.getters['experience/getNonSpaceClick']();
    }
  }
};
</script>
<style scoped src="@/assets/css/player/nonuserspace.css"></style>
